import {t} from "i18next";

const ERR_AUTH_FAILED = 4;

export default class Base {
  constructor(state, authDispatch) {

    this.apiUrl = state.apiUrl;
    this.errNotifier = state.notifier;
    this.state = state;
    this.authDispatch = authDispatch;

    this.needErrorNotify = true;

    this.headers = {
      'Host': this.apiUrl,
      'Accept': '*/*',
      'Origin': this.apiUrl,
      'Content-Type': 'application/json',
    };
  }

  wrapResponse(promiseResp, notify = true) {
    const result = promiseResp
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status + ': ' + response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => {
        if (!json.success) {
          json.errors && json.errors.forEach(err => {
            if (notify) {
              this.errNotifier({ title: err.title, description: err.description });
            }
            if (this.state.token && err.code === ERR_AUTH_FAILED) {
              this.authDispatch({type: "logout"})
            }
          });
        }
        return json;
      }).catch(err => {
        const data = { success: false, data: null, errors: [{ code: 1, title: t("somethingWentWrong"), description: t("unableToParseJson") }] };
        if (notify) {
          this.errNotifier({ title: t("somethingWentWrong"), description: err.toString() });
        }
        return data;
      });

    this.needErrorNotify = true;

    return result
  }
}