import React, {useContext, useEffect, useState} from 'react'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
// or scss:
import 'react-image-crop/lib/ReactCrop.scss';

import './Profile.scss'

import FlatBtn from '../common/FlatBtn'
import Modal from 'react-modal'
import LeftMenu from './LeftMenu'
import {ImgButton} from '../common/ImgButton'

import {ReactComponent as ImgBag} from '../common/img/profile.svg'
import {ReactComponent as ImgLock} from '../common/img/lock.svg'
import ActionButton from '../common/ActionButton'
import {AuthContext} from '../../lib/auth/Context'
import useApi from '../../lib/hooks/useAPI'
import ProfileEdit from './ProfileEdit'
import ProfileEditPass from './ProfileEditPass'
import Loader from '../common/Loader'
import config from '../../config'
import {useParams} from 'react-router-dom'
import QRCode from 'react-qr-code'
import {useTranslation} from 'react-i18next'
import {useAvatar} from '../../Services/Hooks/useAvatar/index'
import {AvatarLargeCircle} from '../common/AvatarCircle'
import { useHistory } from 'react-router-dom'

function Profile({userId, onClose}) {
  if (!onClose) {
    onClose = () => setShowMenu(true)
  }

  const history = useHistory()

  const api = useApi()
  const {compId} = useParams()
  const {state, dispatch} = useContext(AuthContext)

  const [user, setUser] = useState(null)
  const [companyId, setCompanyId] = useState(compId)
  const [company, setCompany] = useState(null)
  const [showMenu, setShowMenu] = useState(false)
  const [showProfileEdit, setShowProfileEdit] = useState(false)
  const [showProfileEditPass, setShowProfileEditPass] = useState(false)

  const {t} = useTranslation()

  const getUser = async (userId) => {
    let params
    if (userId) {
      params = {userId: userId}
    }
    const res = await api.get('user.get', params)
    if (!res.success || !res.data.users) {
      return
    }

    setUser(res.data.users[0])

    if (!companyId) {
      if (res.data.users[0].companies && res.data.users[0].companies.length > 0) {
        setCompanyId(res.data.users[0].companies[0])
      }
    }
  }

  const getCompany = async (companyId) => {
    if (!companyId) {
      return
    }

    const res = await api.get('company.get', {companyId: companyId})
    if (!res.success || !res.data.companies) {
      return
    }
    setCompany(res.data.companies[0])
  }

  const authUnderUser = async (companyId) => {
    if (!companyId) {
      return
    }

    const res = await api.get('company.authUnderUser', {companyId: companyId, userNumber: user.number})
    if (!res.success) {
      return
    }

    api.state.token = res.data.token
    dispatch({type: "login", payload: res.data})

    history.push("/")
  }

  useEffect(() => {
    getUser(userId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCompany(companyId)
  }, [companyId]) // eslint-disable-line react-hooks/exhaustive-deps

  let hiddenFile

  const {
    onPhotoChangeClick, onImageLoaded, onCropComplete, onCropChange, uploadFile, progress, src, crop, imgLoading,
  } = useAvatar(userId, null, setUser, setCompany)

  const canShow = () => {
    return (state.rights === config.rights.root || state.id === userId || window.location.href.indexOf('profile') > -1)
  }

  return (<div className="admin central noselect">
    <div className="app-container admin__app-container">
      <div className="admin__header">
        <FlatBtn type="prev" onClick={() => onClose()}/>
        <p className="header-text">{t('profile')}</p>
      </div>
      <div className="admin__center">
        {(!user || imgLoading) && (<div className="d-flex flex-grow-1">
          <Loader type="dot"/>
        </div>)}
        <div className="profile__avatar">
          {user && !imgLoading && (<AvatarLargeCircle photo={user ? user.photo : null} onClickHandler={() => hiddenFile.click()}/>)}
          {(user || company) && (<>
            <input style={{display: 'none'}} type="file" ref={(input) => {
              hiddenFile = input
            }}
              onChange={onPhotoChangeClick}/>
            {imgLoading && <Loader type="dot"/>}
          </>)}
          {src && (<>
            <div className="profile__crop">
              <ReactCrop src={src} crop={crop} ruleOfThirds onImageLoaded={onImageLoaded} onComplete={onCropComplete} onChange={onCropChange}/>
            </div>
            {progress < 100 && (<ActionButton text={progress ? progress + '%' : t('save')} onClick={() => uploadFile()}/>)}
            {progress >= 100 && <Loader type="dot"/>}
          </>)}
        </div>
        {user && !src && (<div className="profile__actions">
          <div className="profile__action-item">
            <p className="center middle-font">
              {user.name} {user.surname}
              <span className="small-font">&nbsp; (id: {user.number})</span>
            </p><br/>
            {canShow() && (<p className="between middle-font">
              <span className="small-font">{t('balance')}: &nbsp;</span>
              {user ? user.balance : 0} {t('KZT')}
            </p>)}
            <p className="between">
              <span className="small-font">{t('phone')}: &nbsp;</span>
              {user.phone}
            </p>
          </div>
          {canShow() && (<>
            <br/>
            <div className="profile__action-item">
              <ImgButton image={<ImgBag/>} text={t('editData')} onClick={() => setShowProfileEdit(true)}/>
            </div>
            <div className="profile__action-item">
              <ImgButton image={<ImgLock/>} text={t('changePassword')} onClick={() => setShowProfileEditPass(true)}/>
            </div>
          </>)}
          {(state.rights === config.rights.root || state.rights === config.rights.admin )&&  <div className="profile__action-item">
              <ImgButton onClick={() => authUnderUser(companyId)} text={"Войти под пользователем"} />
            </div>}
          {company && (<a className="center" href={'https://pay.coinbox.kz'  + '/tips/' + company.externalId + '?u=' + user.number + '&c=' + company.number}
            target="_blank" rel="noreferrer">
            <div className="menu_qr">
              <QRCode value={'https://pay.coinbox.kz' + '/tips/' + company.externalId + '?u=' + user.number + '&c=' + company.number} size={100}/>
            </div>
          </a>)}
        </div>)}
      </div>
    </div>
    <Modal portalClassName="left-slide-window" isOpen={showMenu} contentLabel="Menu" closeTimeoutMS={300} className="admin-modal"
      overlayClassName="admin-modal__overlay">
      <LeftMenu onClose={() => setShowMenu(false)}/>
    </Modal>
    <Modal portalClassName="left-slide-window" isOpen={showProfileEdit} contentLabel="Profile-edit" closeTimeoutMS={300} className="admin-modal"
      overlayClassName="admin-modal__overlay">
      <ProfileEdit user={user} onClose={() => setShowProfileEdit(false)}/>
    </Modal>
    <Modal portalClassName="left-slide-window" isOpen={showProfileEditPass} contentLabel="Profile-edit-pass" closeTimeoutMS={300}
      className="admin-modal" overlayClassName="admin-modal__overlay">
      <ProfileEditPass user={user} onClose={() => setShowProfileEditPass(false)}/>
    </Modal>
  </div>)
}

export default Profile
