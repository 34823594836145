import React, { useContext, useEffect, useState } from 'react';

import Modal from 'react-modal';

import './Money.scss';

import useApi from '../../lib/hooks/useAPI';
import FlatBtn from '../common/FlatBtn';
import { AvatarLargeCircle } from '../common/AvatarCircle';

import CardAdd from './CardAdd';
import CardList from './CardList';
import LeftMenu from './LeftMenu';
import useNotify from '../../lib/hooks/Notify';
import { ImgInput } from '../common/ImgInput';
import ActionButton from '../common/ActionButton';

import { ReactComponent as ImgKZT } from '../common/img/kzt.svg';
import { ReactComponent as ImgCard } from '../common/img/card.svg';
import { ReactComponent as ImgRightPointer } from '../common/img/right_pointer.svg';
import config from '../../config';
import { AuthContext } from '../../lib/auth/Context';
import Loader from '../common/Loader';
import { useTranslation } from 'react-i18next';

const NotifyModal = ({isOpen, onClose}) => <Modal 
        contentLabel='Уведомление'          
        isOpen={isOpen}
        overlayClassName={'admin-modal__overlay'}
        className={'admin-modal'}        
      >
    <div style={{padding:20, backgroundColor:"#333", borderRadius:"10px", paddingTop:40,paddingBottom:40, width:"80%", display:'flex', flexDirection:"column",
      justifyContent:"center", alignItems:"center", gap:12}}>
     <div style={{fontWeight:"600", fontSize:"16px"}}>Важное уведомление!</div>
        <div style={{fontSize:"14px", textAlign:"center"}}>
          На стороне банка ведутся технические работы, вывод средств временно недоступен.
        </div>
        <button className='action-btn action-btn_style_white' onClick={onClose}>Ознакомлен</button>
    </div>
  </Modal>


const TermsModal = ({isOpen, onClose}) => <Modal 
        contentLabel='Terms Modal'          
        isOpen={isOpen}
        overlayClassName={'admin-modal__overlay'}
        className={'admin-modal'}        
      >
    <div style={{padding:20, backgroundColor:"#333", borderRadius:"10px", paddingTop:40,paddingBottom:40, width:"80%", display:'flex', flexDirection:"column",
      justifyContent:"center", alignItems:"center", gap:12}}>
     <div style={{fontWeight:"600", fontSize:"16px"}}>Важное уведомление!</div>
        <div style={{fontSize:"14px", textAlign:"center"}}>
          С 1 июня 2024 года вводится минимальный сбор за вывод средств. Сумма меньше 10 000 тенге облагается минимальным сбором 150 тенге.
        </div>
        <button className='action-btn action-btn_style_white' onClick={onClose}>Закрыть</button>
    </div>
  </Modal>


function Money() {
  const api = useApi();
  const [notifyModalOpen, setNotifyModalOpen] = useState(true);


  const { state } = useContext(AuthContext);

  const notify = useNotify();

  const [amount, setAmount] = useState(null);

  const [user, setUser] = useState(null);

  const [showAddCard, setShowAddCard] = useState(false);
  const [showCardList, setShowCardList] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [cards, setCards] = useState([]);
  const [pickedCard, setPickedCard] = useState(null);

  const [isCardsLoad, setIsCardsLoad] = useState(false);

  const [payLoader, setPayLoader] = useState(false);
  const [clicked, setClicked] = useState(false);
  
  const [termsModalOpen,setIsTermsModalOpen] = useState(false);

  const { t } = useTranslation();

  const handleCloseTermsModal = () => {
    setIsTermsModalOpen(false);
    api.post('api/v1/user/set-agreement', {userID: user.id});
  }

  const getUser = async () => {
    const res = await api.get('user.get', {"percent": "1"});
    if (!res.success || !res.data.users) {
      return;
    }

    setUser(res.data.users[0]);
    setIsTermsModalOpen(!res.data.users[0].isAgreeWithRates)
    if (res.data.users[0].balance >= 1000) {
      setAmount(res.data.users[0].balance);
    }
  };

  const getCards = async () => {
    const res = await api.get('card.get');
    if (!res.success) {
      setIsCardsLoad(true);
      return;
    }

    if (res.data.cards && res.data.cards.length > 0) {
      setCards(res.data.cards);
      let pick = false;
      res.data.cards.forEach((card) => {
        if (card.isPicked) {
          setPickedCard(card);
          pick = true;
        }
      });
      if (!pick) {
        setPickedCard(res.data.cards[0]);
      }
    }
    setIsCardsLoad(true);
  };

  const moneyWithdraw = async () => {
    if (state.rights !== config.rights.root && amount < 1000) {
      notify({ title: `${t('minimumAmount')} 1000₸` });
      return;
    }

    if (user.balance < amount) {
      notify({ title: t('insufficientFunds') });
      return;
    }

    setPayLoader(true);
    setClicked(true);

    const res = await api.post('money.withdraw', { amount: parseInt(amount) });
    if (!res.success) {
      setPayLoader(false);
      return;
    }
    setPayLoader(false);
    notify({ type: 'success', title: t('success') });
    setAmount(0);
    getUser();
  };

  useEffect(() => {
    getCards();
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getComissionMessage = () => {
    const isComission = amount < 10000;
    const amountIsOk = amount >= 1000;
    return <div style={{paddingLeft:"48px", marginTop:"8px", flexDirection:"column", display:'flex', fontSize:'13px', fontWeight:"500", color:amountIsOk?(!isComission?'#219653':"#F2C94C"):'#828282'}}>
      <div style={{display:'flex', flexDirection:'row', gap:"2px"}}>
      <div>{amountIsOk?'Комиссия':'При сумме менее'}</div>
      <div style={{color: '#E0E0E0'}}>{`${amountIsOk ? (!isComission?0:150) :10000} ₸`}</div>
      </div>

      <div style={{display:'flex', flexDirection:'row', gap:"2px"}}>
      <div>{amountIsOk?'Итоговая сумма':'Будет взиматься комиссия'}</div>
      <div style={{color: '#E0E0E0'}}>{`${amountIsOk?(!isComission?amount:Number(amount)-150):150} ₸`}</div>
      </div>
      </div>
  }

  return (
    <div className="admin central noselect">
      <div className="app-container admin__app-container">
        <div className="admin__header">
          <FlatBtn type="burger" onClick={() => setShowMenu(true)} />
          <p className="header-text">{t('money')}</p>
        </div>
        <div className="admin__center">
          <div className="short-info">
            <div className="admin__avatar">
              <AvatarLargeCircle photo={user ? user.photo : null} />
            </div>
            <div className="short-info__name">
              {user && user.name + ' ' + user.surname + '(id: ' + user.number + ')'}
            </div>
            <div className="short-info__balance">
              <p className="short-info__balance-value big-font">{user ? user.balance : 0} ₸</p>
              <p className="gray-text">{t('myBalance')}</p>
            </div>
          </div>
          {/* {<p className="red-text">Вывод временно не доступен, исправляем.</p>} */}
          <div className="admin__sum">
            <ImgInput
              image={<ImgKZT />}
              type="number"
              placeholder={t('amountToWithdraw')}
              field={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            { user && amount > user.balance && (
              <p className="center red-text">{t('insufficientFundsInTheAccount')}</p>
            )}
            {getComissionMessage()}
          </div>
          <div className="admin__add-card">
            {!isCardsLoad && <Loader type="dot" />}
            {isCardsLoad && !pickedCard && (
              <ActionButton text={`+ ${t('addCard')}`} onClick={() => setShowAddCard(true)} />
            )}
          </div>
          {pickedCard && (
            <div
              onClick={() => setShowCardList(true)}
              className="card-list__item admin__picked-card">
              <div className="card-list__img">
                <ImgCard />
              </div>
              <div className="card-list__info">
                <p className="card-name">{pickedCard.issuer}</p>
                <p className="card-number">{pickedCard.number}</p>
              </div>
              <div className="card-list__pick">
                <ImgRightPointer />
              </div>
            </div>
          )}
          <div className="money__withdraw-btn">
            <ActionButton text={t('cashOut')} disabled={!pickedCard || !amount || clicked || amount > user.balance || amount < 1000} onClick={moneyWithdraw} loader={payLoader}
            />
          </div>
        </div>
        <div className="admin__footer"></div>
      </div>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showAddCard}
        contentLabel="CardAdd"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay">
        <CardAdd onClose={() => setShowAddCard(false)} />
      </Modal>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showCardList}
        contentLabel="CardList"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay">
        <CardList
          cards={cards}
          setCards={setCards}
          onClose={() => setShowCardList(false)}
          onPlus={() => {
            setShowCardList(false);
            setShowAddCard(true);
          }}
        />
      </Modal>
     <NotifyModal isOpen={notifyModalOpen} onClose={() => setNotifyModalOpen(false)}/>
      <Modal
        portalClassName="left-slide-window"
        isOpen={showMenu}
        contentLabel="Menu"
        closeTimeoutMS={300}
        className="admin-modal"
        overlayClassName="admin-modal__overlay">
        <LeftMenu onClose={() => setShowMenu(false)} />
      </Modal>
      <TermsModal isOpen={termsModalOpen} onClose={handleCloseTermsModal}/>
    </div>
  );
}

export default Money;
